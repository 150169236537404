<template>
  <div>
    <v-row
      dense
      class="mt-4"
    >
      <v-col sm="12">
        <v-alert
          icon="mdi-chart-box-outline"
          text
          type="info"
        >
          <strong>
            Este dashboard foi montado com os
            <span style="text-decoration: underline"
              >dados das importações mais recentes</span
            >
            efetuadas no sistema. As datas das importações mais recentes podem
            ser visualizadas na tabela
            <span style="text-decoration: underline"
              >Últimas bases importadas</span
            >
            exibida abaixo.
          </strong>
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            Área de Concessão e Subestações
          </div>
          <v-row>
            <v-col cols="12">
              <area-concessao nome="Área de Concessão" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            Últimas bases importadas
          </div>
          <v-row>
            <v-col cols="12">
              <ultimas-bases-importadas nome="Últimas bases importadas" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            Idade Média do Ativo
          </div>
          <v-row>
            <v-col cols="12">
              <idade-media-ativo nome="Idade Média do Ativo" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">VOC, DAC, RES</div>
          <v-row>
            <v-col cols="12">
              <voc-dac-res nome="VOC, DAC, RES" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            VOC Adições do Período Incremental
          </div>
          <v-row>
            <v-col cols="12">
              <voc-adicoes-periodo-incremental
                nome="VOC Adições do Período Incremental"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">% DAC</div>
          <v-row>
            <v-col cols="12">
              <perc-dac nome="% DAC" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            QTD BB AIS e BDGD por TUC
          </div>
          <v-row>
            <v-col cols="12">
              <qtd-bb-ais-bdgd nome="QTD BB AIS e BDGD por TUC" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            QTD BI AIS e BDGD por TUC
          </div>
          <v-row>
            <v-col cols="12">
              <qtd-bi-ais-bdgd nome="QTD BI AIS e BDGD por TUC" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col sm="4">
        <v-card
          class="ma-0 pa-0"
          height="100%"
        >
          <div class="caption font-weight-light ml-2 pt-2">
            QTD Consolidado por TUC
          </div>
          <v-row>
            <v-col cols="12">
              <qtd-consolidado nome="QTD Consolidado por TUC" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import tourMixins from '@/mixins/tourMixins';
import AuthService from '@/services/AuthService';

export default {
  name: 'DashboardBrr',
  mixins: [tourMixins],
  components: {
    VocDacRes: () =>
      import('@/components/general/graficos/dashboard/VocDacRes.vue'),
    PercDac: () =>
      import('@/components/general/graficos/dashboard/PercDac.vue'),
    IdadeMediaAtivo: () =>
      import('@/components/general/graficos/dashboard/IdadeMediaAtivo.vue'),
    VocAdicoesPeriodoIncremental: () =>
      import(
        '@/components/general/graficos/dashboard/VocAdicoesPeriodoIncremental.vue'
      ),
    QtdBbAisBdgd: () =>
      import('@/components/general/graficos/dashboard/QtdBbAisBdgd.vue'),
    QtdBiAisBdgd: () =>
      import('@/components/general/graficos/dashboard/QtdBiAisBdgd.vue'),
    QtdConsolidado: () =>
      import('@/components/general/graficos/dashboard/QtdConsolidado.vue'),
    AreaConcessao: () =>
      import('@/components/general/graficos/dashboard/AreaConcessao.vue'),
    UltimasBasesImportadas: () =>
      import(
        '@/components/general/graficos/dashboard/UltimasBasesImportadas.vue'
      )
  },
  mounted() {
    const arrTour = this.getArrTour();
    this.iniciarTour(arrTour);
  },
  methods: {
    getArrTour() {
      const arrTour = [
        {
          title: 'Bem-vindo ao TBDGD',
          intro: `Este breve tutorial apresenta instruções básicas para navegação no sistema.<br><br>
                  Para desabilitá-lo basta fechar esta caixa de mensagem.<br> Caso queira acessar o tutorial posteriormente, clique no botão <b>(?)</b> no menu lateral.
                  `
        },
        {
          element: '#core-navigation-drawer',
          title: 'Menu',
          intro:
            'Este é o menu principal, nele estão as principais funcionalidades do <b>TBDGD</b>.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#drawer-section-profile',
          title: 'Perfil',
          intro:
            'Aqui você poderá visualizar informações pessoais (nome, login, cargo, empresas liberadas) e também é possivel deslogar do sistema.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#drawer-section-dashboard',
          title: 'Dashboard',
          intro:
            'Esta é a tela inicial do <b>TBDGD</b>, onde são apresentados os gráficos e principais informações da empresa selecionada.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#drawer-section-criaçãodemapas',
          title: 'Mapas',
          intro:
            'Neste menu você poderá acessar as ferramentas georreferenciadas, podendo criar mapas e fazer análises baseadas em dados geográficos (BDGD).',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#drawer-section-powerups',
          title: 'Power Ups',
          intro:
            'Aqui você acessa as ferramentas exclusivas de Gestão de BRR, como algoritmos de Fiscalização ANEEL, dentre outras.',
          scrollTo: 'tooltip',
          position: 'right'
        }
      ];
      if (AuthService.userIsAdmin()) {
        arrTour.push({
          element: '#drawer-section-geraçãodabdgd',
          title: 'Geração da BDGD',
          intro:
            'Aqui você pode acessar as funcionalidades relacionadas a geração da BDGD, como importação, validação e exportação da BDGD para arquivos Shapefile.',
          scrollTo: 'tooltip',
          position: 'right'
        });
        arrTour.push({
          element: '#drawer-section-perdastécnicas',
          title: 'Perdas Técnicas',
          intro:
            'Aqui você pode acessar as funcionalidades relacionadas a etapa 17 do nVAL, com as validações relacionadas a Perdas Técnicas e Conectividade.',
          scrollTo: 'tooltip',
          position: 'right'
        });
      }
      arrTour.push({
        element: '#drawer-section-relatórios',
        title: 'Relatórios',
        intro:
          'Aqui você poderá fazer relatórios sobre as bases de dados ou usar relatórios modelos.',
        scrollTo: 'tooltip',
        position: 'right'
      });
      if (AuthService.userIsAdmin()) {
        arrTour.push({
          element: '#drawer-section-dados',
          title: 'Dados',
          intro:
            'Aqui você poderá realizar importações de AIS, Laudos e outras bases.',
          scrollTo: 'tooltip',
          position: 'right'
        });
        arrTour.push({
          element: '#drawer-section-administração',
          title: 'Administração',
          intro:
            'Utilize esse menu para fazer configurações no sistema e efetuar cadastros de forma global na aplicação.',
          scrollTo: 'tooltip',
          position: 'right'
        });
      }
      arrTour.push({
        element: '#drawer-section-tutorial',
        title: 'Tutorial',
        intro:
          'Clicando nessa opção você poderá acessar o tutorial sempre que precisar.',
        scrollTo: 'tooltip',
        position: 'right'
      });
      return arrTour;
    }
  }
};
</script>

<style scoped>
.caption {
  text-transform: uppercase;
}
</style>
